import { createRef, PureComponent } from 'react';
import FurnitureHouesePicker from '../component/FurnitureHouesePicker';
import { getFormFields, trimAddressFields, trimCustomerAddress } from 'Util/Address';
import { FURNITURE_HOUSE_CARRIER_CODE } from '../component/FurnitureHouesePicker/FurnitureHousePicker.config';

export class CheckoutShippingPlugin extends PureComponent { 
    selectFurnitureHouse(house) {
        this.setState({
            selectedFurnitureHouse: house
        });
    }

    addContainerFunctions = (member, context) => {
        return {
            ...member,
            onFurnitureHouseSelect: this.selectFurnitureHouse.bind(context)
        }
    }

    renderFurnitureHousePicker(args, callback, instance) {
        const { selectedShippingMethod, onFurnitureHouseSelect } = instance.props;

        return (
            <>
                {callback(...args)}
                <FurnitureHouesePicker
                    onSelect={ onFurnitureHouseSelect }
                    active={selectedShippingMethod && selectedShippingMethod.method_code == "furniturehouse"}
                />
            </>
        )
    }

    ship = (args, callback, instance) => {
        const [fields] = args;
        const {
            saveAddressInformation,
            updateShippingFields,
            addressLinesQty
        } = instance.props;

        const {
            selectedCustomerAddressId,
            selectedShippingMethod,
            selectedStoreAddress,
            selectedFurnitureHouse
        } = instance.state;

        const formFields = getFormFields(fields, addressLinesQty);

        const shippingAddress = selectedCustomerAddressId
            ? instance._getAddressById(selectedCustomerAddressId)
            : trimAddressFields(formFields);

        const {
            carrier_code: shipping_carrier_code,
            method_code: shipping_method_code
        } = selectedShippingMethod;

        const data = {
            billing_address: shippingAddress,
            shipping_address: selectedStoreAddress ? instance.getStoreAddress(shippingAddress) : shippingAddress,
            shipping_carrier_code,
            shipping_method_code,
            shipping_furniture_house: shipping_carrier_code === FURNITURE_HOUSE_CARRIER_CODE ? selectedFurnitureHouse : null
        };

        saveAddressInformation(data);
        updateShippingFields(fields);
    }
}

const { renderFurnitureHousePicker, addContainerFunctions, ship } = new CheckoutShippingPlugin();

export default {
    'Component/CheckoutShipping/Component': {
        'member-function': {
            renderDelivery: renderFurnitureHousePicker,
        }
    },
    'Component/CheckoutShipping/Container': {
        'member-function': {
            'onShippingSuccess': {
                implementation: ship,
                position: 0
            }
        },
        'member-property': {
            containerFunctions: addContainerFunctions,
        }
    },
}